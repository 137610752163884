import React from "react";
import PropTypes from "prop-types";
import Layout from "../../layouts/Layout";
import Seo from "../seo";
import Intro from "../common/Intro";
import Breadcrumb from "../common/Breadcrumb";
import { getShareImage } from "../../utils/getShareImage";

import imgFile from "../../images/common/file.svg";
import imgGetinweb from "../../images/common/getinweb.png";

const Press = props => {
  const { lang, t, layoutT, servicesT, postsT } = props.pageContext;
  const { pathname } = props.location;

  return (
    <Layout t={layoutT} servicesT={servicesT} posts={postsT} lang={lang} pathname={pathname}>
      <Seo title={t.seo.title} lang={lang} description={t.seo.description} image={getShareImage(lang)} />

      <Intro title={t.intro.title} />

      <Breadcrumb lang={lang} actualPage={t.seo.title} />

      <div className="Press">
        <section className="section Press__mainSection">
          <div className="container">
            <p>{t.mainSection.description}</p>
            <a className="Press__email" href={`mailto:${t.contact.email}`}>
              {t.contact.email}
            </a>
          </div>
        </section>

        <section className="section section--gray">
          <div className="container">
            <h2 className="title">{t.downloads.title}</h2>

            <a className="Press__download" href={imgGetinweb} download="getinweb.png" title="getinweb.png">
              <img src={imgFile} alt="getinweb.png" />
              getinweb.png
            </a>
          </div>
        </section>
      </div>
    </Layout>
  );
};

Press.propTypes = {
  pageContext: PropTypes.object.isRequired
};

export default Press;
